import React from 'react'
import './_banner.scss'
import { Link } from 'gatsby'

import icon1 from '../../images/banner/icon1.svg'
import icon2 from '../../images/banner/icon2.svg'
import icon3 from '../../images/banner/icon3.svg'
import listImage from '../../images/banner/banner-list-image.png'
import whiteArrowIcon from '../../images/banner/white-arrow.svg'

const Banner = () => {
  return (
    <section className="banner">
      <div className="banner__inner">
        <div className="banner__left">
          <h1 className="banner__headline">
            Personalized Mortgage News and Tools
          </h1>
          <p className="banner__tagline">
            from <span>Experts You Can Trust</span>
          </p>
          <Link to="/calculator" className="banner__button">
            Calculate a Mortgage
          </Link>
        </div>
        <div className="banner__right">
          <ul className="banner__list">
            <li className="banner__list__item">
              <Link
                className="banner__list__link"
                to="develop"
              >
                <div className="banner__list__link-inner">
                  <img src={icon1} alt="icon" className="banner__list__icon"/>
                  <span className="banner__list__name">
                  Guides
                </span>
                  <div className="banner__list__arrow">
                    <img src={whiteArrowIcon} alt="white arrow"/>
                  </div>
                </div>
              </Link>
            </li>
            <li className="banner__list__item">
              <Link
                className="banner__list__link"
                to="/calculator"
              >
                <div className="banner__list__link-inner">

                  <img src={icon2} alt="icon" className="banner__list__icon"/>
                  <span className="banner__list__name">
                  Tools
                </span>
                  <div className="banner__list__arrow">
                    <img src={whiteArrowIcon} alt="white arrow"/>
                  </div>
                </div>
              </Link>
            </li>
            <li className="banner__list__item banner__list__item--empty">
              <img src={listImage} alt="home example"/>
            </li>
            <li className="banner__list__item">
              <Link
                className="banner__list__link"
                to="develop"
              >
                <div className="banner__list__link-inner">
                  <img src={icon3} alt="icon" className="banner__list__icon"/>
                  <span className="banner__list__name">
                  Rates Today
                </span>
                  <div className="banner__list__arrow">
                    <img src={whiteArrowIcon} alt="white arrow"/>
                  </div>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}
export default Banner