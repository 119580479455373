import React from 'react'
import Layout from '../component/Layout/Layout'
import FreeTools from '../component/FreeTools/FreeTools'
import Newsletter from '../component/Newsletter/Newsletter'
import Rates from '../component/Rates/Rates'
import Blog from '../component/Blog/Blog'
import Guides from '../component/Guides/Guides'
import Banner from '../component/Banner/Banner'
import Seo from '../component/Seo'

const IndexPage = () => {

  return (
    <Layout>
      <Seo title='Demo Mortgage' />
      <Banner/>
      <Rates/>
      <FreeTools/>
      <Guides/>
      <Blog/>
      <Newsletter/>
    </Layout>
  )
}

export default IndexPage
