import React from 'react'
import toolsIcon1 from '../../images/free-tools/tools1.svg'
import toolsIcon2 from '../../images/free-tools/tools2.svg'
import toolsIcon3 from '../../images/free-tools/tools3.svg'
import toolsIcon4 from '../../images/free-tools/tools4.svg'
import arrowIcon from '../../images/free-tools/right-arrow.svg'

import './_free-tools.scss'
import { Link } from 'gatsby'

const FreeTools = () => {
  return (
    <section className="free-tools">
      <div className="free-tools__inner">
        <div className="free-tools__head">
          <span className="free-tools__tagline tagline">calculators</span>
          <h2 className="free-tools__headline">
            Additional Free Tools
          </h2>
        </div>
        <ul className="free-tools__list">
          <li className="free-tools__item">
            <Link
              className="free-tools__link"
              to="/calculator"
            >
              <div className="free-tools__information">
                <img src={toolsIcon1} alt="tools icon 1" className="free-tools__image free-tools__image--1"/>
                <p className="free-tools__name">
                  Mortgage Calculator
                </p>
                <p className="free-tools__description">
                  Discover what your monthly payment will be and thus learn how much house you can afford.
                </p>
                <div className="free-tools__button">
                    <span className="free-tools__button__text">
                       Get Started
                    </span>
                  <img src={arrowIcon} alt="arrow icon"/>
                </div>
              </div>
            </Link>
          </li>
          <li className="free-tools__item">
            <Link
              to="/develop"
              className="free-tools__link"
            >
              <div className="free-tools__information">
                <img src={toolsIcon2} alt="tools icon 1" className="free-tools__image  free-tools__image--2"/>
                <p className="free-tools__name">
                  Refinance Calculator
                </p>
                <p className="free-tools__description">
                  Mortgage refinancing means replacing your current home loan with a new loan. Learn how much you can save as a result.
                </p>
                <div className="free-tools__button">
                    <span className="free-tools__button__text">
                       Get Started
                    </span>
                  <img src={arrowIcon} alt="arrow icon"/>
                </div>
              </div>
            </Link>
          </li>
          <li className="free-tools__item">
            <Link
              to="/develop"
              className="free-tools__link"
            >
              <div className="free-tools__information">
                <img src={toolsIcon3} alt="tools icon 1" className="free-tools__image  free-tools__image--3"/>
                <p className="free-tools__name">
                  FHA Calculator
                </p>
                <p className="free-tools__description">
                  Estimate your monthly payment and total cost with an FHA home loan.
                </p>
                <div className="free-tools__button">
                    <span className="free-tools__button__text">
                       Get Started
                    </span>
                  <img src={arrowIcon} alt="arrow icon"/>
                </div>
              </div>
            </Link>
          </li>
          <li className="free-tools__item">
            <Link
              to="/develop"
              className="free-tools__link"
            >
              <div className="free-tools__information">
                <img src={toolsIcon4} alt="tools icon 1" className="free-tools__image  free-tools__image--4"/>
                <p className="free-tools__name">
                  VA Loan Calculator
                </p>
                <p className="free-tools__description">
                  Estimate your monthly payments and total cost with a VA home loan.
                </p>
                <div className="free-tools__button">
                    <span className="free-tools__button__text">
                       Get Started
                    </span>
                  <img src={arrowIcon} alt="arrow icon"/>
                </div>
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </section>
  )
}
export default FreeTools