import React, { useState } from 'react'
import picture from '../../images/newslatter/picture.png'
import './_newsletter.scss'

const Newsletter = () => {
  const [viewMessage, setViewMessage] = useState(false)

  const sendData = (e) => {
    e.preventDefault()
    setTimeout(() => {
      setViewMessage(true)
    }, 500)

    const inputNode = document.getElementsByClassName('newsletter__email-input')
    for (const item of inputNode) {
      item.value = ''
    }

    setTimeout(() => {
      setViewMessage(false)
    }, 2500)

  }

  return (
    <section className="newsletter">
      <div className="newsletter__inner">
        <div className="newsletter__container">
          <div className="newsletter__left">
            <span className="newsletter__tagline tagline">newsletter</span>
            <h2 className="newsletter__headline">Stay informed with what's important to you</h2>
            <div className="newsletter__image-container">
              <img src={picture} alt="newsletter" className="newsletter__image"/>
            </div>
          </div>
          <div className="newsletter__right">
            <form className="newsletter__form" onSubmit={sendData}>
              <div className="newsletter__checkbox-inner">
                <input type="checkbox" id="refinancing" className="newsletter__label__input"/>
                <label htmlFor="refinancing" className="newsletter__label">
                  <div className="newsletter__custom-checkbox"></div>
                  <span className="newsletter__label__text">Refinancing</span>
                </label>
                <input type="checkbox" id="homePurchase" className="newsletter__label__input"/>
                <label htmlFor="homePurchase" className="newsletter__label">
                  <div className="newsletter__custom-checkbox"></div>
                  <span className="newsletter__label__text"> Home Purchase</span>
                </label>
                <input type="checkbox" id="mortgageRates" className="newsletter__label__input"/>
                <label htmlFor="mortgageRates" className="newsletter__label">
                  <div className="newsletter__custom-checkbox"></div>
                  <span className="newsletter__label__text">Mortgage Rates</span>
                </label>
                <input type="checkbox" id="news" className="newsletter__label__input"/>
                <label htmlFor="news" className="newsletter__label">
                  <div className="newsletter__custom-checkbox"></div>
                  <span className="newsletter__label__text">News</span>
                </label>
              </div>
              <div className="newsletter__button-inner">
                <input
                  type="email"
                  className="newsletter__email-input"
                  placeholder="Enter email address"
                  required
                />
                <button className="newsletter__submit">
                  Sign me up!
                </button>
                {viewMessage ?
                  <p className="newsletter__success-message">
                    You have successfully subscribed!
                  </p>
                  : null
                }

              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Newsletter