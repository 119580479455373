import React, { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper'
import './_blog.scss'
import 'swiper/swiper-bundle.css'

import blog1 from '../../images/blog/blog1.png'
import blog2 from '../../images/blog/blog2.jpg'
import blog3 from '../../images/blog/blog3.jpg'
import user from '../../images/blog/user.png'
import { Link } from 'gatsby'

SwiperCore.use([Navigation, Pagination, Autoplay])
const Blog = () => {
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  const blog = [
    {
      image: blog1,
      name: 'Open houses',
      headline: 'Time to Tour',
      text: 'Open houses can help you get a sense of the housing stock in the area, and what is meant by a dog-trot house or a railroad flat...',
      userAvatar: user,
      userName: 'John Smith',
      postDate: 'February 30, 2021'
    },
    {
      image: blog2,
      name: 'Estate Broker',
      headline: 'Find a Real Estate Broker',
      text: 'You can find homes for sale on your own, but a good broker can help you make sound decisions and guide you through the home buying process....',
      userAvatar: user,
      userName: 'John Smith',
      postDate: 'March 12, 2021'
    },
    {
      image: blog3,
      name: 'Analyze',
      headline: 'Analyze Your Market',
      text: 'Look for comparable homes of a similar size that have recently sold nearby to help determine a fair offer. A good real estate agent will pull....',
      userAvatar: user,
      userName: 'John Smith',
      postDate: 'March 22, 2021'
    },
  ]

  const renderSwiperList = (items) => {
    return items.map((item, index) => {
      return (
        <SwiperSlide key={index} style={{ background: `url(${item.image})`, backgroundSize: `cover` }}>
          <Link to="/develop" className="blog__link">
            <div className="blog__information">
              <span className="blog__information__name">{item.name}</span>
              <p className="blog__information__headline">{item.headline}</p>
              <p className="blog__information__text">{item.text}</p>
              <div className="user-information">
                <img
                  src={item.userAvatar}
                  alt={item.userName}
                  className="user-information__avatar"
                />
                <div className="user-information__container">
                  <p className="user-information__name">{item.userName}</p>
                  <p className="user-information__date">{item.postDate}</p>
                </div>
              </div>
            </div>
            {/*<div className="blog__absolute-arrow">*/}
            {/*  <p className="blog__absolute-arrow__text">*/}
            {/*    Read more*/}
            {/*  </p>*/}
            {/*</div>*/}
          </Link>
        </SwiperSlide>
      )
    })
  }

  return (
    <section className="blog">
      <div className="blog__inner">
        <div className="blog__left">
          <span className="blog__tagline tagline">
              blog
            </span>
          <h2 className="blog__headline">
            See What's New From Our Experts
          </h2>
          <p className="blog__description">
            Discover strategies for saving on a down payment, applying for a mortgage, looking for a house, and many more.
          </p>
          <div className="blog__navigate ">
            <button
              className="blog__navigate__button blog__navigate__button--prev"
              ref={navigationPrevRef}
            >
              <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.5 1L2 7.5M2 7.5L8.5 14M2 7.5H18.5" stroke="#4064E4" strokeWidth="2"/>
              </svg>

            </button>
            <button
              className="blog__navigate__button blog__navigate__button--next"
              ref={navigationNextRef}
            >
              <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 14L16.5 7.5M16.5 7.5L10 1M16.5 7.5L-5.68248e-07 7.5" stroke="white" strokeWidth="2"/>
              </svg>
            </button>
            <Link className="blog__navigate__all-articles" to="/develop">
              See all Articles
            </Link>
          </div>
        </div>
        <div className="blog__right">
          <Swiper
            className="stack__swiper"
            slidesPerView={1}
            loop={true}
            updateOnWindowResize={true}

            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}

            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current
              swiper.params.navigation.nextEl = navigationNextRef.current
            }}
          >
            {
              renderSwiperList(blog)
            }
          </Swiper>
        </div>
      </div>
    </section>
  )
}
export default Blog