import React from 'react'
import './_rates.scss'
import rateBg from '../../images/rates/bg.png'
import { Link } from 'gatsby'

const Rates = () => {
  return (
    <section className="rates">
      <div className="rates__inner">
        <div className="rates__left">
          <span className="rates__tagline tagline">rates  Today</span>
          <h2 className="rates__headline">
            Check Today’s Mortgage Rates
          </h2>
          <p className="rates__description">
            Home prices continue to accelerate while inventory remains low and new home construction cannot happen fast enough. There are many potential homebuyers who would like to take advantage of
            low mortgage rates, but the competition is strong. For homeowners, however, continued low rates make refinancing an option worth considering.
          </p>
          <ul className="current-price">
            <li className="current-price__item">
              <p className="current-price__number">
                2.875 <span>%</span>
              </p>
              <span className="current-price__name">
                Today’s lowest rate
              </span>
            </li>
            <li className="current-price__item">
              <p className="current-price__number">
                <span>$</span> 198,785
              </p>
              <span className="current-price__name">
                Average home price
              </span>
            </li>
          </ul>
          <div className="rates__buttons--container">
            <Link
              className="rates__compare-btn"
              to="/calculator"
            >
              Calculate a Mortgage
            </Link>
            <Link
              className="rates__view-by-state"
              to="/develop"
            >
              View by states
            </Link>
          </div>
        </div>
        <div className="rates__right">
          <img src={rateBg} alt="rate bg" className="rates__bg"/>
          <ul className="rates__list">
            <li className="rates__item">
              <Link
                className="rates__link"
                to="/develop"
              >
                <span className="rates__name">
                Washington
              </span>
                <p className="rates__text">
                  Today’s Rate
                </p>
                <span className="rates__number">
                2.736%
              </span>
                <p className="rates__text">
                  Average Home Price
                </p>
                <span className="rates__number">
                $409,228
              </span>
                <button className="rates__button">
                  Talk to a Loan Officer
                </button>
              </Link>
            </li>
            <li className="rates__item ">
              <Link
                className="rates__link rates__link--blue"
                to="/develop"
              >
              <span className="rates__name">
                Alabama
              </span>
                <p className="rates__text">
                  Today’s Rate
                </p>
                <span className="rates__number">
                2.781%
              </span>
                <p className="rates__text">
                  Average Home Price
                </p>
                <span className="rates__number">
                $138,975
              </span>
                <button className="rates__button">
                  Talk to a Loan Officer
                </button>
              </Link>
            </li>
            <li className="rates__item">
              <Link
                className="rates__link"
                to="/develop"
              >
              <span className="rates__name">
                Michigan
              </span>
                <p className="rates__text">
                  Today’s Rate
                </p>
                <span className="rates__number">
                2.672%
              </span>
                <p className="rates__text">
                  Average Home Price
                </p>
                <span className="rates__number">
                $409,228
              </span>
                <button className="rates__button">
                  Talk to a Loan Officer
                </button>
              </Link>
            </li>
          </ul>
        </div>
        <div className="rates__buttons--mobile">
          <Link className="rates__compare-btn"
                to="/develop"
          >
            Compare Rates</Link>
          <Link className="rates__view-by-state"
                to="/develop"
          >View by states</Link>
        </div>
      </div>
    </section>
  )
}
export default Rates