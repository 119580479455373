import React, { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import './_guides.scss'
import 'swiper/swiper-bundle.css'

import guidesImage1 from '../../images/guides/bg.png'
import linkArrow from '../../images/guides/right-arrow.svg'
import { Link } from 'gatsby'

SwiperCore.use([Navigation, Pagination])
const Guides = () => {
  const guidesPrevRef = useRef(null)
  const guidesNextRef = useRef(null)

  const guides = [
    {
      name: 'Rent vs. Buy?',
      text: 'When looking for a new place to live, the first question you ask yourself will help drive the rest of your decision-making...',
    },
    {
      name: 'How Much House Can I Afford?',
      text: 'To determine how much you can spend on a home, take a close look at your budget. Review your bank statements and spending...',
    },
    {
      name: 'Count Your Pennies',
      text: 'Have you decided to buy? Before you jump into the world of combing online home listings, attending open houses and vetting ...',
    },
    {
      name: 'Choose a Neighborhood',
      text: 'What makes a good neighborhood? The answer to that question is going to be different for everyone. But you can quickly...',
    },
  ]

  const renderSwiperList = (items) => {
    return items.map((item, index) => {
      return (
        <SwiperSlide key={index}>
          <div className="guides__counter">
            {index + 1}
          </div>
          <div className="guides__information__container">
            <p className="guides__name">{item.name}</p>
            <p className="guides__text">{item.text}</p>
            <Link className="guides__link" to="/develop">
              Read More <img src={linkArrow} alt="arrow link"/>
            </Link>
          </div>
        </SwiperSlide>
      )
    })
  }

  return (
    <section className="guides">
      <div className="guides__inner">
        <div className="guides__left">
          <span className="guides__tagline tagline">guides</span>
          <h2 className="guides__headline">Buying a Home?</h2>
          <p className="guides__description">
            Our <span>step-by-step guide </span> will direct you through the process from start to finish. Know what to expect, no matter where you are in your
            home buying journey.
          </p>
          <Link className="guides__button" to="/develop">
            View all Guides
          </Link>
        </div>
        <div className="guides__right">
          <img src={guidesImage1} alt="bg" className="guides__right__image"/>
          <div className="guides__information">
            <div className="guides__navigate">
              <button
                className="guides__navigate__button guides__navigate__button--prev"
                ref={guidesPrevRef}
              >
                <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.5 1L2 7.5M2 7.5L8.5 14M2 7.5H18.5" stroke="#4064E4" strokeWidth="2"/>
                </svg>
              </button>
              <button
                className="guides__navigate__button guides__navigate__button--next"
                ref={guidesNextRef}
              >
                <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 14L16.5 7.5M16.5 7.5L10 1M16.5 7.5L-5.68248e-07 7.5" stroke="white" strokeWidth="2"/>
                </svg>
              </button>
            </div>
            <Swiper
              className="guides__swiper"
              slidesPerView={1}
              loop={true}
              updateOnWindowResize={true}
              navigation={{
                prevEl: guidesPrevRef.current,
                nextEl: guidesNextRef.current,
              }}

              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = guidesPrevRef.current
                swiper.params.navigation.nextEl = guidesNextRef.current
              }}
            >
              {
                renderSwiperList(guides)
              }
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Guides